import Styles from './input.module.scss';

export const Input = ({
	type,
	styles,
	value,
	onChange,
	placeholder
}) => {
	return (
		<input
			className={`${Styles.input} ${styles && styles}`}
			value={value}
			onChange={onChange}
			type={type}
			placeholder={placeholder} />
	);
};
