import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
	FacebookIcon,
	InstagramIcon,
	MenuIcon,
	TiktokIcon,
	YoutubeIcon
} from 'assets/socialsMenu';
import Styles from './socialsMenu.module.scss';

export const SocialsMenu = () => {
	const [isExpanded, setIsExpanded] = useState(false);

	const socials = [
		{ icon: InstagramIcon, url: 'https://www.instagram.com/welcometoomaar' },
		{ icon: YoutubeIcon, url: 'https://www.youtube.com/@OmarBaig-xy9qj' },
		{ icon: FacebookIcon, url: 'https://www.facebook.com/profile.php?id=100090089304198' },
		{ icon: TiktokIcon, url: 'https://www.tiktok.com/@omarbaigg' }
	];

	return (
		<div
			className={`${Styles.socialsMenu} ${isExpanded ? Styles.expandedMenu : ''}`}
			onMouseLeave={() => setIsExpanded(false)}>
			<div className={Styles.menuButton} onMouseEnter={() => setIsExpanded(true)}>
				<MenuIcon />
			</div>
			<div className={Styles.socials}>
				{socials.map((socialPlatform) => (
					<Link
						key={socialPlatform.url}
						to={socialPlatform.url}
						className={Styles.socialPlatform}
						rel="noopener noreferrer"
						target="_blank">
						<socialPlatform.icon />
					</Link>
				))}
			</div>
		</div>
	);
};
