import {
	ArrowRight,
	MyStoryImage,
	PlusSigns,
	Sticks,
	Sushi,
	ZigzagStrip
} from 'assets/myStorySection';
import Styles from './myStorySection.module.scss';

export const MyStorySection = () => {
	return (
		<div className={Styles.myStorySectionContainer}>
			<div className={Styles.titleSection}>
				<div className={Styles.title}>
					<h1>My Story</h1>
					<div className={Styles.tile} />
					<div className={`${Styles.tile} ${Styles.colored}`}>
						<Sushi className={Styles.sushi} />
					</div>
				</div>
				<Sticks className={Styles.sticks} />
			</div>
			<div className={Styles.bioSection}>
				<div className={Styles.tileLarge}>
					<h3>
						Hailing from your average family in Karachi,
						Pakistan it had never crossed my mind that
						I'd one day be in Japan, going through this
						fabulous culinary journey with thousands of you. ‹3
					</h3>
					<a href='/coming-soon' className={Styles.linkButton}>
						Read My Story
						<ArrowRight className={Styles.arrowRight} />
					</a>
					<div className={Styles.storyImage}>
						<img src={MyStoryImage} width={'100%'} height={'100%'} alt='Story' />
					</div>
				</div>
				<PlusSigns className={Styles.plusSigns} />
			</div>
			<div className={Styles.grayBlock}>
				<div className={Styles.tileLarge}>
					<ZigzagStrip />
				</div>
			</div>
		</div>
	);
};
