import { useEffect } from 'react';
import Lottie from 'lottie-web';
import {
	AbstractSticks,
	HeroSectionLottie,
	HeroSectionPortrait,
	TileOneImageA,
	TileOneImageB,
	TileTwoImageA,
	TileTwoImageB
} from 'assets/heroSection';
import { Tile } from './tile';
import Styles from './heroSection.module.scss';

const TileImages = [
	TileOneImageA,
	TileOneImageB,
	TileTwoImageA,
	TileTwoImageB
];

export const HeroSection = ({ lottieID }) => {
	const firstTileBlankColors = { 3: Styles.tilePurple };
	const secondTileBlankColors = { 2: Styles.tileSalmon, 5: Styles.tileGreen };

	useEffect(() => {
		Lottie.loadAnimation({
			container: document.querySelector(`#${lottieID}`),
			renderer: 'svg',
			loop: true,
			autoplay: true,
			animationData: HeroSectionLottie,
			rendererSettings: {
				preserveAspectRatio: 'xMidYMid slice',
				viewBoxSize: '63 58 470 484'
			}
		});
	}, [lottieID]);

	return (
		<div className={Styles.heroSectionContainer}>
			<div className={Styles.titleContainer}>
				<h1 className={Styles.title}>Main hoon Omar</h1>
				<div className={Styles.grayBlock} />
			</div>
			<div className={Styles.tileContainer}>
				<Tile
					images={TileImages.slice(0, 2)}
					blankIndices={[3]}
					blankColors={firstTileBlankColors}
					className={`${Styles.tiles} ${Styles.left}`}
					tileItemStyle={Styles.tileItem}
					totalItems={5} />
				<Tile
					images={TileImages.slice(2)}
					blankIndices={[2, 5]}
					blankColors={secondTileBlankColors}
					className={`${Styles.tiles} ${Styles.right}`}
					tileItemStyle={Styles.tileItem}
					totalItems={5} />
			</div>
			<div className={Styles.heroSectionLottieContainer}>
				<AbstractSticks className={Styles.abstractSticks} />
				<div className={Styles.heroSectionLottie} id={lottieID} />
			</div>
			<div className={Styles.portraitContainer}>
				<div className={Styles.portrait}>
					<img src={HeroSectionPortrait} alt='portrait' />
				</div>
			</div>
			<h1 className={Styles.sectionHeading}>
				a japanese pakistani chef
			</h1>
		</div>
	);
};
