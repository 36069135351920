import { useState } from 'react';
import { NoGiveawayText, VerticalBars, WhiteStrips } from 'assets/giveawaySection';
import { Button } from 'components/button';
import { Input } from 'components/input';
import Styles from './giveawaySection.module.scss';

export const GiveawaySection = () => {
	const [isGiveawayActive] = useState(false);

	return (
		<div className={Styles.giveawaySectionContainer}>
			<div className={Styles.headingSection}>
				<h1>Giveaways</h1>
				<div className={Styles.backgroundShapes}>
					<VerticalBars className={Styles.verticalBars} />
					<WhiteStrips className={Styles.whiteStrips} />
				</div>
			</div>
			{!isGiveawayActive
				? <div className={Styles.formSection}>
					<NoGiveawayText className={Styles.noGiveawayText} />
					<div className={Styles.newsletterForm}>
						<p>
							Sign up for email updates from Omar
							to find out when the next giveaway starts!
						</p>
						<Input type='email' placeholder='Your email address' />
						<Button text='subscribe' />
					</div>
				</div>
				: <></>
			}
		</div>
	);
};
