import { useEffect } from 'react';
import Lottie from 'lottie-web';
import Styles from './card.module.scss';

export const Card = ({
	cardStyles,
	children,
	image,
	link,
	lottie,
	lottieContainerStyles,
	lottieID,
	lottieRendererSettings,
	lottieSpeed = 1
}) => {
	useEffect(() => {
		const animate = Lottie.loadAnimation({
			container: document.querySelector(`#${lottieID}`),
			renderer: 'svg',
			loop: true,
			autoplay: true,
			animationData: lottie,
			rendererSettings: {
				preserveAspectRatio: 'none',
				...lottieRendererSettings
			}
		});

		animate.setSpeed(lottieSpeed);
	}, [lottie, lottieID, lottieSpeed, lottieRendererSettings]);

	const CardContent = () => {
		return (
			<>
				{lottie && <div className={lottieContainerStyles} id={lottieID} />}
				{image && <img src={image} alt="" width='100%' height='100%' />}
				{children}
			</>
		);
	};

	const CardWrapper = ({
		link,
		children,
		cardStyles
	}) => {
		const Wrapper = link ? 'a' : 'div';

		return (
			<Wrapper
				className={`${Styles.cardContainer} ${cardStyles}`}
				{...(link && { href: link })}
				{...(link && { target: '_blank' })}
			>
				{children}
			</Wrapper>
		);
	};

	return (
		<CardWrapper link={link} cardStyles={cardStyles}>
			<CardContent />
		</CardWrapper>
	);
};
