import { useEffect, useRef } from 'react';
import { ContactSection } from 'components/contactSection';
import { GalleryWallSection } from 'components/galleryWallSection';
import { GiveawaySection } from 'components/giveawaySection';
import { HeroSection } from 'components/heroSection';
import { MyStorySection } from 'components/myStorySection';
import Styles from './landingScreen.module.scss';

export const LandingScreen = () => {
	const pageRef = useRef(null);
	const xAxisRef = useRef(null);
	const touchStartX = useRef(null);
	const touchEndX = useRef(null);

	const resize = () => {
		const page = pageRef.current;
		const lastPane = page.querySelector('#pane:last-of-type');
		const totalWidth = page.scrollWidth - window.innerWidth + window.innerHeight;

		document.body.style.height = `${totalWidth}px`;
		xAxisRef.current = lastPane.getBoundingClientRect().left + window.scrollY;
	};

	const handleVerticalScroll = () => {
		const page = pageRef.current;
		const bodyScrollTop = document.body.getBoundingClientRect().top;
		const verticalOffset = window.scrollY - xAxisRef.current;

		page.scrollLeft = -bodyScrollTop;

		if (verticalOffset > 0) {
			window.scrollTo(0, verticalOffset);
		} else if (window.scrollY === 0) {
			window.scrollTo(0, xAxisRef.current);
		}
	};

	const handleHorizontalScroll = (event) => {
		if (event.deltaX !== 0) {
			window.scrollBy({
				top: event.deltaX,
				behavior: 'auto'
			});
		}
	};

	const handleTouchStart = (event) => {
		touchStartX.current = event.touches[0].clientX;
	};

	const handleTouchMove = (event) => {
		event.preventDefault();

		touchEndX.current = event.touches[0].clientX;

		window.scrollBy({
			top: (touchStartX.current - touchEndX.current) * 2,
			behavior: 'auto'
		});

		touchStartX.current = touchEndX.current;
	};

	useEffect(() => {
		resize();

		window.addEventListener('resize', resize);
		window.addEventListener('scroll', handleVerticalScroll);
		window.addEventListener('wheel', handleHorizontalScroll, { passive: false });
		window.addEventListener('touchstart', handleTouchStart, { passive: true });
		window.addEventListener('touchmove', handleTouchMove, { passive: false });

		return () => {
			window.removeEventListener('resize', resize);
			window.removeEventListener('scroll', handleVerticalScroll);
			window.removeEventListener('wheel', handleHorizontalScroll);
			window.removeEventListener('touchstart', handleTouchStart);
			window.removeEventListener('touchmove', handleTouchMove);
		};
	}, []);

	return (
		<div className={Styles.container} ref={pageRef}>
			<div id='pane' className={Styles.scroll}>
				<HeroSection lottieID='heroSection' />
			</div>
			<div id='pane' className={`${Styles.scroll} ${Styles.storySection}`}>
				<MyStorySection />
			</div>
			<div id='pane' className={Styles.scroll}>
				<GalleryWallSection />
			</div>
			<div id='pane' className={`${Styles.scroll} ${Styles.giveawaySection}`}>
				<GiveawaySection />
			</div>
			<div id='pane' className={Styles.scroll}>
				<ContactSection />
			</div>
			<div id='pane' className={Styles.scroll}>
				<HeroSection lottieID='heroSectionDuplicate' />
			</div>
		</div>
	);
};
