import { useEffect, useState } from 'react';
import Lottie from 'lottie-web';
import { Tile } from 'components/heroSection/tile';
import {
	TileTwoImageA,
	TileTwoImageB
} from 'assets/heroSection';
import {
	EmailIcon,
	FloatingTurtleLottie,
	MailIcon,
	MessageIcon,
	Quote,
	Tomb
} from 'assets/contactSection';
import Styles from './contactSection.module.scss';
import { ContactForm } from './contactForm';

const TileImages = [
	TileTwoImageA,
	TileTwoImageB
];

const contactOptions = [
	{
		key: 'newsletter',
		icon: <MessageIcon />

	},
	{
		key: 'email',
		icon: <EmailIcon />
	},
	{
		key: 'mail',
		icon: <MailIcon />
	}
];

export const ContactSection = () => {
	const [activeOption, setActiveOption] = useState('newsletter');

	const tileBlankColors = { 1: Styles.tilesalmon, 4: Styles.tilegreen };

	const handleContactOptionClick = (option) => {
		setActiveOption(option);
	};

	useEffect(() => {
		Lottie.loadAnimation({
			container: document.querySelector('#turtleLottie'),
			renderer: 'svg',
			loop: true,
			autoplay: true,
			animationData: FloatingTurtleLottie,
		});
	}, []);

	return (
		<div className={Styles.contactSectionContainer}>
			<div id='turtleLottie' className={Styles.turtleLottie} />
			<div className={Styles.tileContainer}>
				<Tile
					images={TileImages}
					blankIndices={[1, 4]}
					blankColors={tileBlankColors}
					className={Styles.tiles}
					tileItemStyle={Styles.tileItem}
					totalItems={4} />
				<div className={Styles.sectionHeading}>
					<h1>Contact</h1>
				</div>
			</div>
			<div className='d-flex flex-column gap-2'>
				{contactOptions.map((contact) => (
					<div
						key={contact.key}
						className={`${Styles.contactOptionCard} ${Styles[contact.key]} ${
							contact.key === activeOption && Styles.active
						}`}
						onClick={() => handleContactOptionClick(contact.key)}>
						{contact.icon}
					</div>
				))}
			</div>
			<ContactForm option={activeOption} />
			<div className={Styles.quoteContainer}>
				<Quote className={Styles.quote} />
				<img class={Styles.tomb} src={Tomb} alt='tomb' />
			</div>
		</div>
	);
};
