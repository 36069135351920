import { useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

export const AppRouter = ({ routes }) => {
	return (
		<Routes>
			{routes.map(({
				path, title, component, isPrivate, isRestricted
			}) => (
				<Route
					key={path}
					path={path}
					element={
						<RouteComponent
							title={title}
							component={component}
							isPrivate={isPrivate}
							isRestricted={isRestricted}
						/>
					}
				/>
			))}
		</Routes>
	);
};

const RouteComponent = ({
	title,
	component: Component,
	isPrivate,
	isRestricted
}) => {
	const [user] = useState();

	document.title = title ? `${title} | Sutaa Talent` : 'Sutaa Talent';

	if (user && isRestricted) {
		return <Navigate to="/" replace />;
	}

	if (!user && isPrivate) {
		return <Navigate to="/sign-in" replace />;
	}

	return <Component />;
};
