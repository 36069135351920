import {
	GalleryFoodDisplay,
	GalleryPortrait,
	JapaneseText,
	PlusSigns,
	PotLottie,
	Quote,
	RamenLottie,
	SkewersLottie,
	SushiTrayLottie
} from 'assets/galleryWallSection';
import { Card } from './card';
import Styles from './galleryWallSection.module.scss';

export const GalleryWallSection = () => {
	return (
		<div className={Styles.gallerySectionContainer}>
			<div className={Styles.leftSection}>
				<div className={Styles.columnOne}>
					<Card cardStyles={Styles.galleryPortrait} image={GalleryPortrait} />
					<Card
						cardStyles={Styles.ramenLottieCard}
						lottie={RamenLottie}
						lottieContainerStyles={Styles.ramenLottie}
						lottieID='ramenLottie'
						lottieSpeed={0.5} />
					<Card cardStyles={Styles.beigeCard} />
				</div>
				<div className={Styles.columnTwo}>
					<Card cardStyles={Styles.squidCard} image={GalleryFoodDisplay} />
					<Card
						cardStyles={Styles.potLottieCard}
						lottie={PotLottie}
						lottieContainerStyles={Styles.potLottie}
						lottieID='potLottie'
						lottieSpeed={0.7} />
				</div>
				<div className={Styles.columnThree}>
					<Card cardStyles={`${Styles.darkBlueCard} ${Styles.small}`} />
					<Card cardStyles={Styles.advertCard}>
						<p>Ad</p>
					</Card>
				</div>
				<div className={Styles.columnFour}>
					<Card cardStyles={Styles.grayCard} />
					<Card cardStyles={`${Styles.darkBlueCard} ${Styles.large}`} />
				</div>
				<div className={Styles.columnFive}>
					<Card
						cardStyles={Styles.sushiTrayLottieCard}
						lottie={SushiTrayLottie}
						lottieID='sushiTrayLottie'
						lottieRendererSettings={{
							preserveAspectRatio: 'xMidYMid slice',
							viewBoxSize: '0 0 4350 2161'
						}} />
					<Card cardStyles={Styles.purpleCard} />
				</div>
			</div>
			<div className={Styles.rightSection}>
				<JapaneseText className={Styles.japaneseText} />
				<div className={Styles.columnTwo}>
					<Card cardStyles={`${Styles.darkBlueCard} ${Styles.small}`} />
					<Card cardStyles={Styles.advertCard}>
						<p>Ad</p>
					</Card>
				</div>
				<div className={Styles.columnThree}>
					<Card cardStyles={Styles.advertCard}>
						<p>Ad</p>
					</Card>
					<Card cardStyles={Styles.smallCard} />
					<PlusSigns className={Styles.plusSigns} />
				</div>
				<div className={Styles.columnFour}>
					<Quote className={Styles.quote} />
					<Card
						cardStyles={Styles.skewersLottieCard}
						lottieContainerStyles={Styles.skewersLottie}
						lottie={SkewersLottie}
						lottieID='sushiLottie'
						lottieSpeed={0.5} />
				</div>
			</div>
		</div>
	);
};
