export const Tile = ({
	images,
	blankIndices,
	blankColors,
	className,
	tileItemStyle,
	totalItems
}) => {
	return (
		<div className={className}>
			{Array.from({ length: totalItems }).map((_, index) => {
				const tileIndex = index + 1;
				const isBlank = blankIndices.includes(tileIndex);
				const tileStyle = isBlank ? { backgroundColor: blankColors[tileIndex] } : {};
				const imageIndex = [...Array(tileIndex).keys()].filter(
					(i) => !blankIndices.includes(i + 1)
				).length;

				return (
					<div className={tileItemStyle} key={tileIndex} style={tileStyle}>
						{isBlank ? null : (
							<img
								src={images[(imageIndex - 1) % images.length]}
								alt={`tile ${tileIndex}`} />
						)}
					</div>
				);
			})}
		</div>
	);
};
