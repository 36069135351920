import { Button } from 'components/button';
import { Input } from 'components/input';
import Styles from './contactSection.module.scss';

export const ContactForm = ({ option = 'mail' }) => {
	const renderContactForm = () => {
		switch (option) {
		case 'newsletter':
			return <>
				<p>
					Sign up for email updates from Omar
					to keep up with everything going on with
					the Japanese Pakistani Chef
				</p>
				<Input type='email' placeholder='Your email address' />
				<Button text='subscribe' />
			</>;
		case 'email':
			return <>
				<p>
					Interested in a collaboration or in marketing with Omar?
					Please reach out to:
				</p>
				<p>omar@sutaa.me</p>
			</>;
		case 'mail':
			return <>
				<p>
					Omar would love to hear from you or receive your packages!<br /><br/>
					Send mail and packages to<br/><br />
					<span>
						<p>
							Omar Baig<br />
							Pakistan Post P.O. Box 13721,
							Federal B Area Block 7, Karachi,
							Pakistan
						</p>
					</span>
					<center>Or</center><br />
					<span>
						<p>
							Japan, 〒331-0823 Saitama, Kita Ward, Nisshinchō,
							3-chōme−754-6 Co-op Koizumi 202
						</p>
					</span>
				</p>
			</>;
		default:
			return null;
		}
	};

	const getDynamicStyles = () => {
		return option === 'mail'
			? { textAlign: 'start', paddingBottom: '40px' }
			: { textAlign: 'center' };
	};

	return (
		<div className={Styles.contactFormContainer} style={getDynamicStyles()}>
			{renderContactForm(option)}
		</div>
	);
};
